import {Injectable} from '@angular/core';
import {setTimestamp} from 'utilities';
import {KeyEnum, ValueEnum} from '../enums/key.enum';
import {ICurrentUser, IProfile, ISearchPostData} from '../interfaces';
import {NORWEGIAN_LANGUAGE_CODES} from '../constants/constants';
import {checkLanguage} from '../utilities';
import {IStoredMsGraphToken} from '../interfaces/i-stored-ms-graph-token';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor() {}

  setProfile(value: IProfile): void {
    this.removeOldProfile();
    sessionStorage.setItem(KeyEnum.Profile, JSON.stringify({value, timestamp: this.timestamp}));
  }

  removeProfile(): void {
    this.removeOldProfile();
    sessionStorage.removeItem(KeyEnum.Profile);
  }

  get profile(): {value: IProfile; timestamp: number} | null {
    this.removeOldProfile();
    const profile = sessionStorage.getItem(KeyEnum.Profile);
    return profile?.length ? JSON.parse(profile) : null;
  }

  setMsGraphToken(value: string, expire: number): void {
    if (value && expire) {
      sessionStorage.setItem(KeyEnum.MsGraphToken, JSON.stringify({expire, value}));
    }
  }

  removeMsGraphToken(): void {
    sessionStorage.removeItem(KeyEnum.MsGraphToken);
  }

  get msGraphToken(): string | null {
    const token = sessionStorage.getItem(KeyEnum.MsGraphToken);
    const storedToken: IStoredMsGraphToken = token ? JSON.parse(token) : null;
    return storedToken?.expire > this.timestamp ? storedToken?.value : null;
  }

  setOfflineDataMarker(): void {
    sessionStorage.setItem(KeyEnum.OfflineDataSet, '1');
  }

  get isOfflineDataSet(): boolean {
    const value = sessionStorage.getItem(KeyEnum.OfflineDataSet);
    return !!value;
  }

  get currentUser(): ICurrentUser | null {
    this.removeOldCurrentUser();
    const currentUser = localStorage.getItem(KeyEnum.CurrentUser);
    return currentUser?.length ? JSON.parse(currentUser) : null;
  }

  setCurrentUser(username: string): void {
    this.removeOldCurrentUser();
    localStorage.setItem(KeyEnum.CurrentUser, JSON.stringify({username}));
  }

  removeCurrentUser(): void {
    this.removeOldCurrentUser();
    localStorage.removeItem(KeyEnum.CurrentUser);
  }

  setLanguage(language: string): void {
    localStorage.setItem(KeyEnum.Language, language);
  }

  getLanguage(profileLanguage?: string): string {
    const language = localStorage.getItem(KeyEnum.Language);
    return (
      checkLanguage(language) ||
      profileLanguage?.toLowerCase() ||
      (NORWEGIAN_LANGUAGE_CODES.includes(navigator?.language) ? ValueEnum.No : ValueEnum.En)
    );
  }

  setSearch(data?: ISearchPostData): void {
    const search = data ? JSON.stringify(data) : '';
    if (search) {
      sessionStorage.setItem(KeyEnum.Search, search);
    }
  }

  /**
   * Updates timestamp to invalidate cache in getCloudfrontParams.
   */
  setTimestamp(): void {
    setTimestamp();
  }

  get searchPostData(): ISearchPostData | null {
    const data = sessionStorage.getItem(KeyEnum.Search);
    return data ? JSON.parse(data) : null;
  }

  get timestamp(): number {
    return new Date().getTime();
  }

  // Previously used data which should be removed to save storage space.

  removeOldCurrentUser(): void {
    localStorage.removeItem('currentUser'); // removes previously used compressed version
  }

  removeOldStore(): void {
    localStorage.removeItem('store'); // removes previously used compressed version
  }

  removeOldSyncTasks(): void {
    localStorage.removeItem('syncTasks'); // removes previously used version
  }

  removeOldProfile(): void {
    sessionStorage.removeItem('profile'); // removes previously used compressed version
  }
}
