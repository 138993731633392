import {createNgModule, Injectable, Injector, ViewContainerRef} from '@angular/core';
import {SwUpdate, VersionReadyEvent} from '@angular/service-worker';
import {fromEvent, Observable, skip} from 'rxjs';
import {filter, first} from 'rxjs/operators';
import {normalizeImport, StyleService} from 'utilities';
import {environment} from '../environments/environment';
import {IProfile, IWebSocketStoresData} from './modules/shared';
import {DBService} from './modules/shared/services/db.service';
import {StoreEnum} from './modules/shared/enums/store-enum';
import {RouteEnum} from './modules/shared/enums/route.enum';
import {ValueEnum} from './modules/shared/enums/key.enum';
import {AuthService} from './modules/shared/services/auth.service';
import {DialogInjectorService} from './modules/shared/services/dialog-injector.service';
import {OrganizationEnum} from './modules/shared/enums/organization.enum';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private readonly themes: {[name: string]: string[]};
  public readonly defaultThemeName: string = 'home';

  constructor(
    private injector: Injector,
    private swUpdate: SwUpdate,
    private authService: AuthService,
    private dbService: DBService,
    private styleService: StyleService,
    private dialogInjectorService: DialogInjectorService
  ) {
    this.themes = {
      [RouteEnum.QaDocuments]: [RouteEnum.QaDocuments],
      [RouteEnum.Documents]: [RouteEnum.Documents, this.routesToUrl([RouteEnum.IStudy, 'statistics'])],
      [RouteEnum.Contacts]: [RouteEnum.Contacts, RouteEnum.Companies],
      [RouteEnum.Incidents]: [RouteEnum.Incidents],
      [RouteEnum.Projects]: [RouteEnum.Projects],
      [RouteEnum.Organization]: [RouteEnum.Organization, RouteEnum.Employees, RouteEnum.Rauma],
      [RouteEnum.Forms]: [RouteEnum.Forms, this.routesToUrl([RouteEnum.IStudy, 'templates'])],
      [RouteEnum.Tasks]: [RouteEnum.Tasks, this.routesToUrl([RouteEnum.IStudy, 'events'])],
      [RouteEnum.Web]: [RouteEnum.Web]
    };
  }

  get isAuthenticated(): boolean {
    return this.authService.isAuthenticated;
  }

  /**
   * Dynamically loads SidenavModule and creates SidenavComponent in {@param container}.
   * @private
   */
  public async initSidenav(container: ViewContainerRef): Promise<void> {
    this.authService.setDefaultLang();
    const {SidenavModule} = await normalizeImport(import('./modules/shared/layout/sidenav/sidenav.module'));
    const ngModuleRef = createNgModule(SidenavModule, this.injector);
    const {SidenavComponent} = await normalizeImport(
      import('./modules/shared/layout/sidenav/sidenav/sidenav.component')
    );
    container.clear();
    container.createComponent(SidenavComponent, {ngModuleRef});
  }

  public subscribeSwUpdate(): void {
    const sub = this.swUpdate.versionUpdates
      .pipe(
        filter((e: VersionReadyEvent) => e?.type === ValueEnum.VersionReady),
        first()
      )
      .subscribe(() => {
        sub.unsubscribe();
        this.openUpdateDialog();
      });
  }

  /**
   * Subscribes for custom LogsEvent from Nordveggen Jobs Module.
   * This solution is more elegant than creation of Subject and export/import/subscribe for it.
   */
  public subscribeLogsEvent(): void {
    if (this.authService.org === OrganizationEnum.Nordveggen) {
      fromEvent(window, 'LogsEvent').subscribe((e: CustomEvent) =>
        this.dialogInjectorService.openChangeLogDialog({logs: e?.detail || []}).subscribe()
      );
    }
  }

  private async openUpdateDialog(): Promise<void> {
    this.dialogInjectorService.openUpdateDialog().subscribe(confirm => (confirm ? location.reload() : null));
  }

  public getThemeName(url: string): string {
    let themeName = this.defaultThemeName;

    Object.keys(this.themes).forEach(name => {
      const routes = this.themes[name].filter(i => url.indexOf(i) === 1);
      if (routes?.length) {
        themeName = name;
      }
    });

    return themeName;
  }

  /**
   * Sets theme according to provided url.
   * Removes url theme when default theme detected (home theme imported into global styles as default).
   * @param url
   */
  public setTheme(url: string): void {
    const newThemeName = this.getThemeName(url);
    if (newThemeName === this.defaultThemeName) {
      this.styleService.removeStyle('theme');
    } else {
      this.styleService.setStyle('theme', `${newThemeName}-theme.css`);
    }
  }

  /**
   * Connects to domino webSocket via compatClient.
   * () => ws connection should be provided as a factory to allow auto reconnect within Stomp.over.
   * Check console for warnings when ws provided without factory (if you don't trust).
   * @param disconnectReason
   */
  async connectWebSocket(disconnectReason?: string): Promise<void> {
    const {Stomp} = await normalizeImport(import('@stomp/stompjs'));
    if (Stomp) {
      if (disconnectReason?.length) {
        console.warn(`${disconnectReason}: ${new Date()}`);
      }
      let wsConnected = false;
      const ws = new WebSocket(`${environment.ibricksWss}/notification`);
      const compatClient = Stomp.over(() => ws);
      compatClient.connect(
        {notesName: this.authService.currentProfile?.notesName},
        () => {
          wsConnected = true;
          compatClient.subscribe(`/notify/queue/${this.authService.org}`, (res: {body: string}): void => {
            if (res?.body?.length) {
              const data: IWebSocketStoresData = JSON.parse(res.body);
              const stores: StoreEnum[] = data ? data.stores || data.endPoint || [] : []; // TODO remove workaround
              console.log({res, data, stores});

              if (stores.length) {
                const sub = this.dbService.clearStores(stores).subscribe(() => sub.unsubscribe());
              }
            }
          });
        },
        () => (wsConnected ? this.connectWebSocket('Subscription failed') : null)
      );

      if (environment.production && !location.host.includes('isend')) {
        compatClient.debug = () => {};
      }

      compatClient.onDisconnect = () => this.connectWebSocket('compatClient.onDisconnect');
      compatClient.onStompError = () => this.connectWebSocket('compatClient.onStompError');
      compatClient.onWebSocketClose = () => this.connectWebSocket('compatClient.onWebSocketClose');
      ws.onclose = () => (wsConnected ? this.connectWebSocket('WebSocket.onclose') : null);
    }
  }

  private routesToUrl(routes: string[]): string {
    return routes.join('/');
  }

  /**
   * Skips initial null value and returns profile response after api call.
   */
  public getProfile(): Observable<IProfile> {
    return this.authService.getProfile().pipe(skip(1));
  }
}
