import {ICountry, IOption} from '../interfaces';
import {ValueEnum} from '../enums/key.enum';
import {LicenseEnum} from '../enums/license.enum';
import {IncidentTypeEnum} from '../enums/type.enum';

export const ALL_READERS_ACCESS: string = '0';

export const PDF_COLOR: string = '#334D8A';

export const NORWAY_COUNTRY: ICountry = Object.freeze({
  code: ValueEnum.NorwayCode,
  name: 'NORGE',
  nameEn: 'NORWAY',
  phoneCode: '47'
});

export const APP_COLORS: readonly string[] = Object.freeze([
  '#009688',
  '#9b0000',
  '#228700',
  '#673ab7',
  '#cf001d',
  '#4d6af1'
]);

export const REG_SUB_MENU: {label: string; value: string; actionList: IOption[]} = Object.freeze({
  label: 'COMMON.NEW-REGISTRATION',
  value: 'registration',
  actionList: []
});

export const DATE_FORMAT = Object.freeze({
  htmlFull: 'dd.MM.yyyy HH:mm',
  htmlDate: 'dd.MM.yyyy',
  htmlShort: 'd.M.yyyy',
  template: 'DD.MM.YYYY',
  hours: 'HH'
});

export const USER_LICENCES = Object.freeze([
  {label: 'SETTINGS.USER-LICENSES.BASIC', value: LicenseEnum.Basic},
  {label: 'SETTINGS.USER-LICENSES.STANDARD', value: LicenseEnum.Standard},
  {label: 'SETTINGS.USER-LICENSES.ISI', value: LicenseEnum.Isi}
]);

export const INCIDENT_TYPE_ABBREVIATIONS = Object.freeze({
  [IncidentTypeEnum.Deviation]: 'RUH',
  [IncidentTypeEnum.Proposal]: 'FF',
  [IncidentTypeEnum.Positive]: 'RØH'
});

export const CONTACT_MAIL_LIST_TYPES: IOption[] = [
  {label: 'MAIL-LISTS.TYPES.INTERNAL', value: ValueEnum.Internal},
  {label: 'MAIL-LISTS.TYPES.MARKETING', value: ValueEnum.Marketing}
];

export const TASK_NOTIFICATION_METHODS: readonly IOption[] = Object.freeze([
  {label: 'COMMON.NONE', value: 'NONE'},
  {label: 'TASKS.NOTIFICATION-METHODS.MAIL', value: 'MAIL'},
  {label: 'TASKS.NOTIFICATION-METHODS.SMS', value: 'SMS'}
]);

export const NORWEGIAN_LANGUAGE_CODES: readonly string[] = Object.freeze([
  'no',
  'nb',
  'nn-NO',
  'nb-NO',
  'da',
  'da-DK',
  'sv',
  'sv-FI',
  'sv-SE'
]);

export const EMPLOYEE_STATUSES: {[key: string]: number} = Object.freeze({
  ACTIVE: 1,
  ONLEAVE: 2,
  LAIDOFF: 3,
  ONLEAVE_NO_SALARY: 4,
  INACTIVE: 9
});
